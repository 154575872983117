import React from 'react';
import getYouTubeId from 'get-youtube-id';
import YouTube from 'react-youtube';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coldarkCold } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { GatsbyImage } from 'gatsby-plugin-image';
import { OutboundLink } from 'gatsby-plugin-amplitude-analytics';

const sanityConfig = {
  projectId: `${process.env.SANITY_PROJECT_ID}`,
  dataset: `${process.env.SANITY_DATASET}`,
};

const portableTextComponents = {
  types: {
    code: ({ value }) => {
      const { language, code } = value;
      const languageName = language ? language : 'text';
      return (
        <div className="font-mono text-sm lg:text-base">
          <SyntaxHighlighter
            wrapLongLines
            language={languageName}
            style={coldarkCold}
          >
            {code}
          </SyntaxHighlighter>
        </div>
      );
    },
    youtube: ({ value }) => {
      const { url } = value;
      const id = getYouTubeId(url);
      return <YouTube videoId={id} className="w-full" />;
    },
    table: ({ value }) => {
      return (
        <div className="my-2 overflow-x-auto">
          <table className="table-fixed border-collapse border bg-gray-100">
            {value.rows.map((row, index) => {
              return (
                <tr>
                  {row.cells.map((cell, index) => {
                    return (
                      <td className="border border-gray-200 px-8 py-3 text-left">
                        {cell}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </table>
        </div>
      );
    },
    image: ({ value }) => {
      console.log('image', value);
      const imageData = getGatsbyImageData(
        value?.asset?.id,
        { maxWidth: 1024 } as any,
        sanityConfig,
      );
      return (
        <div>
          <GatsbyImage image={imageData} alt="" />
        </div>
      );
    },
  },
  block: {
    h2: ({ children }) => (
      <h2 className="mt-10 text-4xl font-bold">{children}</h2>
    ),
    h3: ({ children }) => (
      <h3 className="mt-10 text-2xl font-bold">{children}</h3>
    ),
    normal: ({ children }) => <p className="my-3 lg:my-6">{children}</p>,
    blockquote: ({ children }) => (
      <blockquote className="border-orange-red border-l-4 py-2 pl-2 italic">
        {children}
      </blockquote>
    ),
    hr: ({ children }) => (
      <hr className="border-tealish-blue text-tealish-blue my-10 border border-opacity-40" />
    ),
  },
  list: {
    bullet: ({ children }) => (
      <ul className="mt-2 list-inside list-disc">{children}</ul>
    ),
    number: ({ children }) => (
      <ol className="mt-2 list-inside list-decimal">{children}</ol>
    ),
  },
  marks: {
    link: ({ children, value }) => {
      const target = (value?.href || '').startsWith('http')
        ? '_blank'
        : undefined;
      return (
        <OutboundLink
          href={value.href}
          target={target}
          rel={target === '_blank' && 'noindex'}
          className="text-blue-700 underline transition-all duration-300 hover:text-blue-500"
        >
          {children}
        </OutboundLink>
      );
    },
    strong: ({ children }) => <span className="font-bold">{children}</span>,
    underline: ({ children }) => <span className="underline">{children}</span>,
    em: ({ children }) => <span className="italic">{children}</span>,
    code: ({ children }) => {
      return (
        <span className="bg-ghost-white inline-block whitespace-nowrap rounded-sm border border-gray-300 px-2 py-1 font-mono text-sm">
          <code>{children}</code>
        </span>
      );
    },
    superscript: ({ children }) => <sup>{children}</sup>,
    subscript: ({ children }) => <sub>{children}</sub>,
    highlight: ({ children }) => (
      <span className="bg-yellow-200 p-1">{children}</span>
    ),
    'strike-through': ({ children }) => (
      <span className="line-through">{children}</span>
    ),
  },
};

export default portableTextComponents;
