import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '@/components/common/v4/header';
import Footer from '@/components/common/v5/footer';
import { responsiveMediaStyles } from '@/utils/responsive-media';
import { useStaticQuery, graphql } from 'gatsby';
import InfoBar from '@/components/info-bar';

type layoutProps = {
  children?: React.ReactNode;
  title?: string;
  description?: string;
  image?: string;
  imageWidth?: string;
  imageHeight?: string;
  mainContentVariant?: 'gray' | 'dark';
};

const Layout = ({ children, mainContentVariant = 'dark' }: layoutProps) => {
  const query = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        description
        siteName
        siteUrl
        infoBar {
          infoBarCtaText
          infoBarCtaUrl
          showInfoBar
          countDown
          _rawText(resolveReferences: { maxDepth: 10 })
        }
      }
    }
  `);

  const data = query.sanitySiteSettings;
  const { showInfoBar } = data.infoBar;

  const bgColor = {
    dark: 'bg-smoky-black',
    gray: 'bg-[#f6f7ff]',
  };

  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <style>{responsiveMediaStyles}</style>
        <script
          async
          src="https://tag.clearbitscripts.com/v1/pk_9b17e3cbc6a1dfd3c88f42273c421a6f/tags.js"
          referrerPolicy="strict-origin-when-cross-origin"
        ></script>
        <script
          type="text/javascript"
          async
          src="https://play.vidyard.com/embed/v4.js"
        ></script>
      </Helmet>
      <div
        className={`flex min-h-full flex-col ${bgColor[mainContentVariant]} ${
          showInfoBar ? 'pt-[5.5rem] lg:pt-28' : 'pt-5 lg:pt-10'
        }`}
      >
        {showInfoBar && <InfoBar infoBar={data.infoBar} />}

        <Header showInfoBar={showInfoBar} />
        <main className="grow">{children}</main>
        <div className={`${bgColor.dark} z-10`}>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
